<template>
	<div>
		<PageHeader :title="$route.meta.title" />
		<a-menu
			mode="horizontal"
			:selected-keys="selectedKeys"
			style="font-weight: 501;"
		>
			<a-menu-item v-for="item in reportsMenuList" :key="item.path">
				<router-link :to="{ name: item.pathName }">
					<a-icon :type="item.icon" />
					<span>{{ item.name }}</span>
				</router-link>
			</a-menu-item>
		</a-menu>
		<div class="page-content">
			<router-view />
		</div>
	</div>
</template>

<script>
import { PageHeader } from '../../components'
import { reportsMenuList } from '../../config'
import mixin from '@/utils/mixinPages'

export default {
	name: 'AnalisysPage',
	components: {
		PageHeader,
	},
	mixins: [mixin],
	data() {
		return {
			selectedKeys: [],
			reportsMenuList,
		}
	},
	watch: {
		$route() {
			this.updateMenu()
		},
	},
	created() {
		this.updateMenu()
	},
	methods: {
		updateMenu() {
			const routes = this.$route.matched.concat()
			this.selectedKeys = [routes.pop().path]
		},
	},
}
</script>

<style lang="less" scoped>
.page-content {
	padding: 32px 0;
}
</style>
